import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { scrollTo } from 'dpl/shared/utils';
import { getScrollTop } from 'dpl/util/dom';
import Icon from 'dpl/common/components/Icon';

const DrawerContext = createContext();
export const DrawerItemContext = createContext();

export function DrawerItemTitle({
  className,
  iconCloseName,
  iconOpenName,
  iconClassName,
  iconSize,
  children
}) {
  return (
    <DrawerItemContext.Consumer>
      {({ isOpen, onClick }) => (
        <button
          type="button"
          className={classnames('flex items-center w-100', className)}
          onClick={onClick}
        >
          <div className="flex-auto">{children}</div>
          <Icon
            name={isOpen ? iconOpenName : iconCloseName}
            className={iconClassName}
            height={iconSize}
            width={iconSize}
          />
        </button>
      )}
    </DrawerItemContext.Consumer>
  );
}

DrawerItemTitle.propTypes = {
  className: PropTypes.string,
  iconCloseName: PropTypes.string,
  iconOpenName: PropTypes.string,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.string,
  children: PropTypes.node.isRequired
};

DrawerItemTitle.defaultProps = {
  className: 'pv4',
  iconCloseName: 'fat-arrow-down',
  iconOpenName: 'fat-arrow-up',
  iconClassName: 'ml4 purple',
  iconSize: '24px'
};

export function DrawerItemContent({ children, className }) {
  return (
    <DrawerItemContext.Consumer>
      {({ isOpen }) =>
        isOpen ? <div className={className}>{children}</div> : null
      }
    </DrawerItemContext.Consumer>
  );
}

DrawerItemContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

DrawerItemContent.defaultProps = {
  className: 'pb4'
};

export function DrawerItem({ children, className, itemKey }) {
  return (
    <li className={classnames('DrawerItem', className)}>
      <DrawerContext.Consumer>
        {({ openKey, toggleOpen }) => (
          <DrawerItemContext.Provider
            value={{
              isOpen: itemKey === openKey,
              onClick: e => toggleOpen(itemKey, e)
            }}
          >
            {children}
          </DrawerItemContext.Provider>
        )}
      </DrawerContext.Consumer>
    </li>
  );
}

DrawerItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  itemKey: PropTypes.string.isRequired
};

DrawerItem.defaultProps = {
  className: 'bb b--light-gray'
};

export default class Drawer extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    initialOpenKey: PropTypes.string,
    scrollToTop: PropTypes.bool
  };

  static defaultProps = {
    initialOpenKey: null,
    scrollToTop: true
  };

  state = { openKey: this.props.initialOpenKey };

  toggleOpen = (key, { currentTarget }) => {
    const fromViewport = currentTarget.getBoundingClientRect().top;

    this.setState(
      {
        openKey: key === this.state.openKey ? null : key
      },
      () => {
        this.props.scrollToTop &&
          scrollTo({
            top:
              getScrollTop() +
              currentTarget.getBoundingClientRect().top -
              fromViewport
          });
      }
    );
  };

  render() {
    const { children } = this.props;
    const { openKey } = this.state;

    return (
      <div className="Drawer">
        <ul>
          <DrawerContext.Provider
            value={{ openKey, toggleOpen: this.toggleOpen }}
          >
            {children}
          </DrawerContext.Provider>
        </ul>
      </div>
    );
  }
}
