import React from 'react';
import PropTypes from 'prop-types';

import { BreederProfileDeliverMethodPropTypes } from 'dpl/shared/utils/customPropTypes';
import { DELIVERY_PATH } from 'dpl/shared/constants/urls';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import BadgeList from 'dpl/common/components/BadgeList';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';

import pickupImg from 'dpl/assets/delivery_method-pickup.jpg';
import airportPickupImg from 'dpl/assets/delivery_method-airport_pickup.jpg';
import meetHalfwayImg from 'dpl/assets/delivery_method-meet_halfway.jpg';
// used in litter/puppy overlays only
export function BreederProfileDeliveryOptions({ deliveryMethodsData }) {
  const { delivery_description: deliveryDescription, methods } =
    deliveryMethodsData.value.data;

  return (
    <div className="BreederProfileDeliveryOptions">
      <div className="mb2">
        <BadgeList
          titles={methods.map(({ delivery_method: { name } }) => name)}
        />
      </div>
      <p className="mb2">{deliveryDescription}</p>
      <a href={DELIVERY_PATH} className="primary fw-medium flex items-center">
        Learn More About Delivery
        <Icon className="ml2" name="fat-arrow-right" />
      </a>
    </div>
  );
}

BreederProfileDeliveryOptions.propTypes = {
  deliveryMethodsData: PropTypes.shape({
    value: PropTypes.shape(BreederProfileDeliverMethodPropTypes).isRequired
  }).isRequired
};

function BreederProfileDeliverySectionItem({
  description,
  shortDescription,
  photoUrl
}) {
  return (
    <div className="col-md-6 mb5">
      <div className="ph5">
        <div className="flex">
          <div>
            <SmartImage
              lazy
              Tag="div"
              width="72px"
              height="72px"
              className="cover bg-center br3"
              src={photoUrl}
              crop
            />
          </div>
          <div className="flex-auto pl4">
            <p className="fw-medium">{description}</p>
            <ClampedMultiLineText
              text={shortDescription || ''}
              lineHeight={24}
              numOfLines={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

BreederProfileDeliverySectionItem.propTypes = {
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  photoUrl: PropTypes.string
};

BreederProfileDeliverySectionItem.defaultProps = {
  description: null,
  shortDescription: null,
  photoUrl: null
};

export default function BreederProfileDeliverySection({ deliveryMethods }) {
  const {
    delivery_description_v2: deliveryDescription,
    methods,
    pickup,
    airport_pickup: airportPickup,
    meet_halfway: meetHalfway
  } = deliveryMethods.value.data;

  return (
    <section className="BreederProfileDeliverySection">
      <h5 className="mb6 f5 d-sm-block d-none">Pickup and drop-off options</h5>
      <p
        className="mb6"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: deliveryDescription }}
      />
      <div className="nh5">
        <div className="row no-gutters">
          <BreederProfileDeliverySectionItem
            description={pickup.description}
            shortDescription={pickup.short_description}
            photoUrl={pickupImg}
          />
          {airportPickup && (
            <BreederProfileDeliverySectionItem
              description={airportPickup.description}
              shortDescription={airportPickup.short_description}
              photoUrl={airportPickupImg}
            />
          )}
          {meetHalfway && (
            <BreederProfileDeliverySectionItem
              description={meetHalfway.description}
              shortDescription={meetHalfway.short_description}
              photoUrl={meetHalfwayImg}
            />
          )}
          {methods.map(({ delivery_method: dm }) => (
            <BreederProfileDeliverySectionItem
              key={dm.id}
              description={dm.description}
              shortDescription={dm.short_description}
              photoUrl={dm.photo_url}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

BreederProfileDeliverySection.propTypes = {
  deliveryMethods: PropTypes.shape({
    value: PropTypes.shape(BreederProfileDeliverMethodPropTypes)
  }).isRequired
};
