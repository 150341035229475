import React from 'react';
import { useSelector } from 'react-redux';

import BadgeList from 'dpl/common/components/BadgeList';
import { BreederProfileDeliveryOptions } from 'dpl/components/BreederProfile/Show/BreederProfileDeliverySection';
import BreederProfileFamilyOverlay, {
  BreederProfileFamilyOverlayDetailsSection,
  BreederProfileFamilyOverlayDetailsSectionItems,
  BreederProfileFamilyOverlayTree,
  imageDimensions
} from 'dpl/components/BreederProfile/Show/BreederProfileFamilyOverlay';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import MediaCarousel from 'dpl/components/MediaCarousel';
import ImageCarouselImageCaption from 'dpl/components/MediaCarousel/ImageCarouselImageCaption';
import OverlayNavigationArrow from 'dpl/components/OverlayNavigationArrow';
import { ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import { ANALYTICS } from 'dpl/constants/query_params';
import ModalApplyButton from 'dpl/dog_modals/components/ModalApplyButton';
import { LITTER_STATES } from 'dpl/shared/constants/shared';
import { capitalize, groupBy } from 'dpl/shared/utils';

import useLitterModal from '../hooks/useLitterModal';

const { BORN, EXPECTED, PLANNED } = LITTER_STATES;

export function formatPrefList(preferences) {
  return preferences?.map(p => p.description).join(', ');
}

export default function LitterModal() {
  const {
    litter,
    litterImages,
    included,
    previousLitterId,
    nextLitterId,
    deliveryMethodsData,
    isSuccess,
    isLitterModalOpen,
    onPuppyClick,
    onParentClick,
    onLitterClick,
    onModalClose
  } = useLitterModal();

  const {
    breed_name: breedName,
    breeder_profile: breederProfile,
    description_with_intro: description,
    parents,
    price_range: price,
    puppies,
    status,
    snoozed
  } = litter;

  const { pageName } = useSelector(({ server }) => server);
  const { queryParams } = useSelector(state => state);
  const showNavigationArrows = pageName === 'breeder_profiles_show';
  const reserved = Boolean(puppies.length && puppies.every(p => p.reserved));

  let title = '';
  let details = [['Breed', breedName]];
  const preferences = [
    ['Generation', formatPrefList(litter.generation_preferences_attributes)],
    ['Color', formatPrefList(litter.color_preferences_attributes)],
    ['Size', formatPrefList(litter.size_preferences_attributes)],
    ['Coat type', formatPrefList(litter.coat_type_preferences_attributes)]
  ];

  if (status === BORN) {
    const gendersCount = Object.entries(groupBy(puppies, 'gender'))
      .map(([gender, genderedPuppies]) => {
        const { length } = genderedPuppies;
        const genderUnit =
          length === 1 ? capitalize(gender) : `${capitalize(gender)}s`;
        return `${length} ${genderUnit}`;
      })
      .join(', ');

    title = `Newborn ${breedName} Litter`;
    details = [
      ...details,
      ['Genders', gendersCount],
      ['Age', litter.age_description],
      ['Ready Date', litter.full_go_home_date],
      ...preferences
    ];
  } else if (status === EXPECTED) {
    title = `Expected ${breedName} Litter`;
    details = [
      ...details,
      ['Expected Date', litter.full_birth_date],
      ['Ready Date', `${litter.full_go_home_date} (Approximately)`],
      ...preferences
    ];
  } else if (status === PLANNED) {
    title = `Planned ${breedName} Litter`;
    details = [
      ...details,
      ['Expected Date', litter.full_birth_date],
      ...preferences
    ];
  }

  const analytics = {
    ...queryParams[ANALYTICS],
    breeder_preferred_status: breederProfile?.preferred_qualification?.status,
    view: ANALYTICS_VIEWS.LITTER_MODAL
  };

  if (!isLitterModalOpen) {
    return null;
  }

  return (
    <BreederProfileFamilyOverlay onClose={onModalClose}>
      <div className="BreederProfileLitterOverlay">
        <LoadingWrapper isLoading={!isSuccess} className="fixed absolute--fill">
          {() => (
            <>
              <div
                className="BreederProfileFamilyOverlayHeader"
                data-test-id="BreederProfileFamilyOverlayHeader"
              >
                <div className="flex align-items-center justify-between">
                  <h6 className="mw-60 flex pv4">
                    <p className="pl2 pl8-lg truncate">{title}</p>
                    {price && (
                      <p className="fw-normal nowrap">&nbsp;-&nbsp;{price}</p>
                    )}
                  </h6>
                  <div className="pv1-lg ph1-lg">
                    {reserved || snoozed ? (
                      <p className="pv3 pv4-lg ph8 ph18-lg fw-medium bw0 br3 bg-red white">
                        Reserved
                      </p>
                    ) : (
                      <ModalApplyButton
                        className="pv3 pv4-lg ph4 flex align-items-center"
                        analytics={analytics}
                        breederProfileId={breederProfile.id}
                        breederProfileUrl={breederProfile.profile_url}
                        customCopy="Request info"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="relative">
                {showNavigationArrows && previousLitterId && (
                  <OverlayNavigationArrow
                    direction="left"
                    onClick={() => onLitterClick(previousLitterId)}
                  />
                )}

                {parents.length > 0 ? (
                  <MediaCarousel
                    files={litterImages}
                    showArrowsOnHover
                    renderFileDetails={img =>
                      img.caption && (
                        <div className="dn db-l absolute bottom-0 ml6 mb6 white bg-black-50 br1 f2">
                          <ImageCarouselImageCaption caption={img.caption} />
                        </div>
                      )
                    }
                    dimensions={imageDimensions}
                    smartImageProps={{
                      Tag: 'div',
                      crop: true,
                      className: 'cover bg-center mw-100 min-w-100',
                      loadingClass: 'bg-black'
                    }}
                  />
                ) : (
                  <div
                    className="bg-loading-animation"
                    style={imageDimensions}
                  />
                )}

                {showNavigationArrows && nextLitterId && (
                  <OverlayNavigationArrow
                    direction="right"
                    onClick={() => onLitterClick(nextLitterId)}
                  />
                )}
              </div>
              <div className="ph2 ph7-sm pv6">
                <div className="row">
                  <div className="col-md-8">
                    {description && <p className="f3 mb8">{description}</p>}

                    <BreederProfileFamilyOverlayDetailsSection title="Key Details">
                      <BreederProfileFamilyOverlayDetailsSectionItems
                        details={details}
                      />
                    </BreederProfileFamilyOverlayDetailsSection>

                    {included.length > 0 && (
                      <BreederProfileFamilyOverlayDetailsSection title="Included">
                        <BadgeList titles={included} />
                      </BreederProfileFamilyOverlayDetailsSection>
                    )}

                    <BreederProfileFamilyOverlayDetailsSection title="Pick Up & Delivery">
                      <BreederProfileDeliveryOptions
                        deliveryMethodsData={deliveryMethodsData}
                      />
                    </BreederProfileFamilyOverlayDetailsSection>
                  </div>

                  <div className="col-md-4">
                    <BreederProfileFamilyOverlayTree
                      litter={litter}
                      onPuppyClick={onPuppyClick}
                      onParentClick={onParentClick}
                      title="Parents and Puppies"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </LoadingWrapper>
      </div>
    </BreederProfileFamilyOverlay>
  );
}
